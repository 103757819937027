import {
  RESET_ATTRIBUTES_UPLOAD_DATA,
  UPDATE_ATTRIBUTES_FILE_SPECS,
  UPDATE_ATTRIBUTES_UPLOAD_DATA,
  UPDATE_ATTRIBUTES_UPLOAD_METADATA,
} from '../constants/reducers';
import type { AttributeValuesWebsocketStatus } from '../types/customAttribute';
import type { FileSpecs } from './CustomGroupsReducer';

export interface UploadMetadata {
  readonly type: string;
  readonly status:
    | AttributeValuesWebsocketStatus.Validated
    | AttributeValuesWebsocketStatus.ValidationFailed
    | AttributeValuesWebsocketStatus.UpdatedValues
    | AttributeValuesWebsocketStatus.UpdatedValuesFailed;
  readonly process_id: string;
  readonly file_path: string;
}

export interface Product {
  readonly id: string;
  readonly label?: string;
  readonly is_exists: boolean;
}

export interface Attribute {
  readonly attribute: string;
  readonly attribute_values: Record<string, { value: string }>;
  readonly attribute_id?: string;
  readonly is_exists: boolean;
}

export interface UploadData {
  readonly products: Product[];
  readonly attributes: Attribute[];
}

export interface AttributesState {
  uploadData: UploadData;
  fileSpecs: FileSpecs;
  uploadMetadata: UploadMetadata;
}

interface UpdateAttributesFileSpecs {
  type: typeof UPDATE_ATTRIBUTES_FILE_SPECS;
  fileSpecs: Partial<FileSpecs>;
}

interface UpdateAttributesUploadData {
  type: typeof UPDATE_ATTRIBUTES_UPLOAD_DATA;
  uploadData: UploadData;
}

interface UpdateAttributesUploadMetadata {
  type: typeof UPDATE_ATTRIBUTES_UPLOAD_METADATA;
  uploadMetadata: UploadMetadata;
}

interface ResetAttributesUploadData {
  type: typeof RESET_ATTRIBUTES_UPLOAD_DATA;
}

export type AttributesAction =
  | UpdateAttributesFileSpecs
  | UpdateAttributesUploadData
  | UpdateAttributesUploadMetadata
  | ResetAttributesUploadData;

export const initialAttributesState: AttributesState = {
  uploadData: null,
  fileSpecs: null,
  uploadMetadata: null,
};

export const attributesReducer = (
  state: AttributesState,
  action: AttributesAction
): AttributesState => {
  switch (action.type) {
    case UPDATE_ATTRIBUTES_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.uploadData,
      };
    case UPDATE_ATTRIBUTES_FILE_SPECS: {
      return {
        ...state,
        fileSpecs: {
          ...state.fileSpecs,
          ...action.fileSpecs,
        },
      };
    }

    case UPDATE_ATTRIBUTES_UPLOAD_METADATA:
      return {
        ...state,
        uploadMetadata: action.uploadMetadata,
      };

    case RESET_ATTRIBUTES_UPLOAD_DATA:
      return initialAttributesState;
  }
};
