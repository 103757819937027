import { useContext, useEffect, useReducer } from 'react';
import type { FunctionComponent } from 'react';
import { Button, InlineNotification } from '@carbon/react';
import { DataCategorical, Upload } from '@carbon/icons-react';
import IconTitle from '../IconTitle';
import { AttributePlaceholder } from './AttributePlaceholder';
import { ModalContext } from '../../providers/ModalProvider';
import { CreateAttributeModal } from './CreateAttributeModal';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import { AppContext } from '../../providers/AppProvider';
import type { CustomAttribute } from '../../types/customAttribute';
import { AttributeList } from './AttributeList';
import { WebsocketContext } from '../../providers/WebsocketProvider';
import type {
  AttributeMessage,
  GenericWebsocketMessage,
} from './types/websocket';
import {
  AttributesActionType,
  attributesReducer,
} from './reducers/attributesReducer';
import { WebsocketAction } from '../../constants/websocketMessages';
import { useNavigate } from 'react-router-dom';
import { AttributesContext } from '../../providers/AttributesProvider';

const isAttributeMessage = (
  message: GenericWebsocketMessage | AttributeMessage
): message is AttributeMessage =>
  typeof message?.data?.attribute_id === 'string';

export const AttributeManager: FunctionComponent = () => {
  const navigate = useNavigate();
  const { bannerId, groupId, queryCache } = useContext(AppContext);
  const { broadcastChannel } = useContext(WebsocketContext);
  const { renderComponent, toggleModal } = useContext(ModalContext);
  const { resetUploadData } = useContext(AttributesContext);

  const [attributeList, dispatch] = useReducer(attributesReducer, null);

  const onMessage = (event: MessageEvent) => {
    const response = JSON.parse(event.data);

    if (!isAttributeMessage(response)) {
      return;
    }

    if (
      response.action === WebsocketAction.Create &&
      String(response.data.user_group) !== String(groupId)
    ) {
      return;
    }

    dispatch({ type: AttributesActionType.Message, data: response });
  };

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel, groupId]);

  const openCreateAttributeModal = () =>
    renderComponent(
      <CreateAttributeModal onClose={() => toggleModal(false)} />,
      'CreateAttributeModal'
    );

  const handleUpdateValuesClick = () => {
    resetUploadData();
    navigate('/attribute-manager/upload');
  };

  return (
    <div className="AttributeManager">
      <div className="AttributeManager__Header" data-testid="page-header">
        <IconTitle
          icon={<DataCategorical size={20} />}
          title="Attribute Manager"
          kind="primary"
          description="Create and manage your custom product attributes here"
          help={
            <>
              <div>Step 1: Create an Attribute.</div>
              <div>
                Step 2: Assign or update attribute values to SKUs by uploading
                them via excel
              </div>
            </>
          }
        />
        <div className="AttributeManager__Actions">
          {attributeList?.length > 0 && (
            <Button
              kind="secondary"
              size="md"
              renderIcon={Upload}
              className="has-icon"
              onClick={handleUpdateValuesClick}
            >
              Update Values
            </Button>
          )}
          <Button
            kind="primary"
            size="md"
            renderIcon={DataCategorical}
            className="has-icon"
            onClick={openCreateAttributeModal}
          >
            Create Attribute
          </Button>
        </div>
      </div>
      <Fetch
        alwaysFetchOnMount={
          !queryCache[CACHE_KEY.CUSTOM_ATTRIBUTES] || !attributeList
        }
        apiUrl={`/configs/${bannerId}/user-groups/${groupId}/custom-attributes`}
        initialData={null}
        loadingMessage="Loading custom attributes..."
        cacheKey={CACHE_KEY.CUSTOM_ATTRIBUTES}
        onReceiveData={(data: CustomAttribute[] | null) => {
          if (data) {
            dispatch({ type: AttributesActionType.Load, data });
          }
        }}
      >
        {attributeList && attributeList.length === 0 && (
          <AttributePlaceholder
            onCreateAttributeClick={openCreateAttributeModal}
          />
        )}
        {attributeList && attributeList.length > 0 && (
          <>
            <InlineNotification
              kind="info"
              title="You can find SKUs with missing attribute values using the Dimension Reference Report"
              className="AttributeManager__Notification"
              lowContrast
              hideCloseButton
            />
            <AttributeList data={attributeList} />
          </>
        )}
      </Fetch>
    </div>
  );
};
