import { useContext, useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { Button } from '@carbon/react';
import { ChevronLeft } from '@carbon/icons-react';
import FormStepper from '../../FormStepper/FormStepper';
import { useNavigate } from 'react-router-dom';
import AttributeReview from './AttributeReview';
import AttributeValidationGrid from './AttributeValidationGrid';
import AttributeUpload from './AttributeUpload';
import AttributeUploadSuccess from './AttributeUploadSuccess';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalContext } from '../../../providers/ModalProvider';
import apiRequest from '../../../api';
import { AppContext } from '../../../providers/AppProvider';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import { AttributesContext } from '../../../providers/AttributesProvider';
import { WebsocketContext } from '../../../providers/WebsocketProvider';
import { AttributeValuesWebsocketStatus } from '../../../types/customAttribute';
import { WebsocketAction } from '../../../constants/websocketMessages';
import type {
  AttributeValuesMessage,
  GenericWebsocketMessage,
} from '../types/websocket';
import { CACHE_KEY } from '../../../constants/api';

const isAttributeValuesMessage = (
  message: GenericWebsocketMessage | AttributeValuesMessage
): message is AttributeValuesMessage => {
  return message?.data?.type === 'update_status';
};

const AttributeForm: FunctionComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { bannerId, groupId, clearCacheForKey } = useContext(AppContext);
  const {
    uploadData,
    fileSpecs,
    uploadMetadata,
    resetUploadData,
    updateUploadMetadata,
  } = useContext(AttributesContext);

  const { updateModal } = useContext(ModalContext);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { broadcastChannel } = useContext(WebsocketContext);

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel, uploadMetadata]);

  const onMessage = (event: MessageEvent) => {
    const response = JSON.parse(event.data);
    if (
      !isAttributeValuesMessage(response) ||
      response.action !== WebsocketAction.Update
    ) {
      return;
    }

    const validationErrorModal: ModalOptions = {
      type: 'error',
      title: 'Something went wrong',
      body: 'An issue occurred while processing your file. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
      isCloseIconVisible: false,
    };

    const { data } = response;
    switch (data.status) {
      case AttributeValuesWebsocketStatus.UpdatedValues: {
        updateUploadMetadata(data);
        setSubmitting(false);
        setSuccess(true);
        clearCacheForKey(CACHE_KEY.HIERARCHY_CUSTOM_ATTRIBUTE);
        break;
      }

      case AttributeValuesWebsocketStatus.UpdatedValuesFailed: {
        resetUploadData();
        updateModal(validationErrorModal);
        break;
      }
    }
  };

  const submitUpdatedValues = async () => {
    setSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest(
        `/custom-attributes/${bannerId}/user-groups/${groupId}/values`,
        'POST',
        token,
        {
          process_id: uploadMetadata.process_id,
        }
      );
    } catch (error) {
      setSubmitting(false);
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
      });
    }
  };

  const hasValidSkusOrAttributes =
    hasInvalidItems(uploadData?.products) ||
    hasInvalidItems(uploadData?.attributes);

  const steps = [
    {
      label: 'Step 1/ Upload File',
      component: <AttributeUpload />,
      nextDisabled:
        fileSpecs?.status === 'uploading' || fileSpecs?.invalid || !fileSpecs,
      isValid: !fileSpecs?.invalid && !!fileSpecs,
    },
    {
      label: 'Step 2/ Check Data',
      component: <AttributeValidationGrid />,
      nextDisabled: hasValidSkusOrAttributes,
      isValid: !uploadData || !hasValidSkusOrAttributes,
    },
    {
      label: 'Step 3/ Review & Submit',
      component: <AttributeReview />,
      nextDisabled: false,
      isValid: true,
      isSubmitStep: true,
    },
  ];

  if (success) {
    return <AttributeUploadSuccess />;
  }

  return (
    <>
      <div className="AttributeForm">
        <Button
          kind="ghost"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          hasIconOnly
          size="sm"
          disabled={false}
          onClick={() => navigate('/attribute-manager')}
        />
        <div className="heading-05">Update Attribute Values</div>
      </div>
      <div className="AttributeForm__stepper">
        <FormStepper
          steps={steps}
          isFormValid={!!uploadMetadata?.process_id && !!uploadData}
          submitting={submitting || success}
          onSubmit={submitUpdatedValues}
          submitText="Update Values"
        />
      </div>
    </>
  );
};
export default AttributeForm;
