import type {
  FunctionComponent,
  RefObject,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { useState, useEffect } from 'react';
import { TextInput } from '@carbon/react';
import { Save, WatsonHealthTextAnnotationToggle } from '@carbon/icons-react';
import type { ExistingCustGroup } from '../../../reducers/CustomGroupsReducer';
import Tooltip from '../../Tooltip';
import { truncateParameterName } from '../../Report/TooltipFormatters';
import type { UpdateCustomGroupParams } from '../Platform/CustomGroups';

export interface CustomGroupsEditProps {
  readonly groupId: string;
  readonly groupName: string;
  readonly itemsCount?: number;
  readonly hoveredGroup: boolean;
  readonly textInputRef: RefObject<HTMLInputElement | null>;
  readonly existingCustGroups: ExistingCustGroup[];
  readonly updateCustomGroup: (params: UpdateCustomGroupParams) => void;
}

const CustomGroupsEdit: FunctionComponent<CustomGroupsEditProps> = ({
  groupId,
  groupName,
  itemsCount,
  hoveredGroup,
  textInputRef,
  existingCustGroups,
  updateCustomGroup,
}) => {
  const [invalidGroupName, setInvalidGroupName] = useState(false);
  const [targetGroup, setTargetGroup] = useState<{
    value: string;
    editId: string;
  }>({
    value: '',
    editId: '',
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setTargetGroup]);

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      textInputRef.current &&
      !textInputRef.current.contains(e.target as Node)
    ) {
      setTargetGroup((prev) => ({
        ...prev,
        editId: '',
      }));
    }
  };

  const handleSave = (isInvalid: boolean) => {
    if (!isInvalid) {
      updateCustomGroup({
        customGroupIds: [groupId],
        targetValue: targetGroup.value,
      });
      setTargetGroup({
        value: '',
        editId: '',
      });
    }
    setInvalidGroupName(isInvalid);
  };

  const renderCustomGroupTextInput = () => {
    const hasGroupName = existingCustGroups.some(
      (gr) => gr.name === targetGroup.value
    );
    const isInvalid = !targetGroup.value || hasGroupName;
    const invalidText = !targetGroup.value
      ? 'Empty field'
      : 'Group name already exists, please enter a unique name';

    return (
      <div ref={textInputRef} className="CustomGroupsEdit__text-input">
        <TextInput
          invalid={invalidGroupName}
          invalidText={invalidText}
          id={groupId}
          className="CustomGroupsUploadTable__cell-text-input--edit"
          labelText="Edit custom group name"
          data-testid={`custom-group-name-change-${groupId}`}
          hideLabel
          value={targetGroup.value}
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoFocus
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setTargetGroup({
              value: value,
              editId: groupId,
            });
            setInvalidGroupName(false);
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
              handleSave(isInvalid);
            }
          }}
        />
        <Tooltip description="Save custom group name" align="bottom-right">
          <Save
            data-testid={`custom-group-save-icon-${groupId}`}
            onClick={(e) => {
              e.stopPropagation();
              handleSave(isInvalid);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderEditIcon = (groupId: string, groupName: string) => {
    return (
      <div className="CustomGroupsUploadTable__edit-wrapper">
        <Tooltip useButtonWrapper description="Edit name" align="top">
          <WatsonHealthTextAnnotationToggle
            data-testid={`custom-group-edit-icon-${groupId}`}
            onClick={(e) => {
              e.stopPropagation();
              setTargetGroup({
                editId: groupId,
                value: groupName,
              });
              setInvalidGroupName(false);
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderGroupName = (name: string) => {
    const { text } = truncateParameterName(name);
    return (
      <div
        className={itemsCount && itemsCount > 0 ? 'CustGroupTable__title' : ''}
      >
        {text !== name ? (
          <Tooltip description={name} align="top" useButtonWrapper>
            <div>{text}</div>
          </Tooltip>
        ) : (
          <span>{text}</span>
        )}
        {!!itemsCount && <span> - ({itemsCount} Items)</span>}
      </div>
    );
  };

  return (
    <div className="CustomGroupsEdit">
      {targetGroup.editId === groupId ? (
        renderCustomGroupTextInput()
      ) : (
        <>
          <div className="CustomGroups__item-title-hover">
            {renderGroupName(groupName)}
          </div>
          {hoveredGroup && renderEditIcon(groupId, groupName)}
        </>
      )}
    </div>
  );
};

export default CustomGroupsEdit;
