import { useContext, useState } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import {
  DataTable,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import '../../styles/components/attributeManager.scss';
import { usePagination } from '../../hooks/usePagination';
import type { CustomAttribute } from '../../types/customAttribute';
import { formatUTCDate } from '../../utils/DateUtils';
import { attributeNames } from './constants';
import type {
  TableCell as TableCellType,
  DataTableRenderProps,
} from './carbon';
import { AttributeCell } from './AttributeList/AttributeCell';
import { useStickyTableHeader } from '../../utils/useStickyTableHeader';
import apiRequest from '../../api';
import { AppContext } from '../../providers/AppProvider';
import { ModalContext } from '../../providers/ModalProvider';
import { ActionButton } from './AttributeList/ActionButton';

const tableId = 'attribute-table';

interface Header<K extends keyof TableRowData = keyof TableRowData> {
  readonly key: K;
  readonly header: string;
  readonly format?:
    | 'string'
    | 'datetime'
    | ((value: TableRowData[K]) => ReactNode);
  readonly isSortable?: boolean;
}

type TableRowData = Omit<
  CustomAttribute,
  'attribute_id' | 'status' | 'attribute'
> & {
  readonly id: CustomAttribute['attribute_id'];
  readonly attribute: {
    readonly name: CustomAttribute['attribute'];
    readonly status: CustomAttribute['status'];
  };
};

interface AttributeListProps {
  readonly data: CustomAttribute[];
}

const getCellContent = ({
  cell: { value },
  header,
}: {
  cell: TableCellType;
  header: Header;
}): ReactNode => {
  const { format } = header;

  if (typeof format === 'function') {
    return format(value);
  }

  if (format === 'datetime') {
    return formatUTCDate(value as string, true);
  }

  return value;
};

export const AttributeList: FunctionComponent<AttributeListProps> = (props) => {
  const { data } = props;

  const { bannerId, groupId } = useContext(AppContext);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const { getAccessTokenSilently } = useAuth0();

  const {
    page,
    pageSizes,
    pageSize,
    isShowAll,
    getItemRangeText,
    setPagination,
  } = usePagination({});

  const { isSticky, scrollbarRef } = useStickyTableHeader({
    rowsLength: data.length,
    tableId,
  });

  const [isSaving, setIsSaving] = useState(false);

  const deleteAttribute = async (
    attributeId: CustomAttribute['attribute_id']
  ): Promise<void> => {
    try {
      setIsSaving(true);

      const token = await getAccessTokenSilently();

      await apiRequest(
        `/configs/${bannerId}/user-groups/${groupId}/custom-attributes/${attributeId}`,
        'DELETE',
        token
      );
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue deleting the attribute. Please try again.',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const confirmAttributeDeletion = (attributeId: string): void => {
    const attribute = data.find(({ attribute_id: id }) => attributeId === id);

    if (!attribute) {
      return;
    }

    const { attribute: attributeName } = attribute;

    updateModal({
      type: 'warning',
      title: `Are you sure you want to delete ${attributeName}?`,
      body: 'Once you delete the attribute, all attribute values associated with the SKUs will also be deleted.',
      primaryCTAText: 'Delete',
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: () => {
        toggleModal(false);
        deleteAttribute(attributeId);
      },
    });
  };

  const headers: Header[] = [
    {
      key: 'attribute',
      header: 'Attribute',
      format: ({ name, status }: TableRowData['attribute']) => (
        <AttributeCell name={name} status={status} />
      ),
    },
    { key: 'sku_count', header: 'SKU Count' },
    {
      key: 'attribute_type',
      header: 'Type',
      format: (value: TableRowData['attribute_type']) => attributeNames[value],
    },
    {
      key: 'last_updated_timestamp',
      header: 'Latest Update Date',
      format: 'datetime',
    },
    { key: 'last_updated_user', header: 'Update Made By' },
    {
      key: 'id',
      header: 'Actions',
      format: (attributeId: string) => (
        <div className="AttributeList__actions-cell">
          <ActionButton
            renderIcon={TrashCan}
            iconDescription="Delete"
            disabled={isSaving}
            onClick={() => confirmAttributeDeletion(attributeId)}
          />
        </div>
      ),
    },
  ];

  const headersByKey = Object.fromEntries(
    headers.map((header) => [header.key, header])
  );

  const tableData: TableRowData[] = data.map(
    ({ attribute_id: id, attribute, status, ...row }) => ({
      id,
      attribute: { name: attribute, status },
      ...row,
    })
  );

  return (
    <div className="AttributeList">
      <DataTable rows={tableData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
        }: DataTableRenderProps<Header>) => {
          const rowStartIndex = (page - 1) * pageSize;

          const visibleRows = isShowAll
            ? rows
            : rows.slice(rowStartIndex, rowStartIndex + pageSize);

          return (
            <TableContainer>
              <div className="data-table-container" id={tableId}>
                <Table {...getTableProps()} className="table-content">
                  <TableHead>
                    <TableRow
                      className={`${isSticky ? 'sticky' : 'visible'}-header`}
                    >
                      {headers.map((header) => (
                        <TableHeader
                          key={header.key}
                          {...getHeaderProps({
                            header,
                            isSortable: header.isSortable,
                            tabIndex: 0,
                          })}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((row) => (
                      <TableRow key={row.id} {...getRowProps({ row })}>
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>
                            {getCellContent({
                              cell,
                              header: headersByKey[cell.info.header],
                            })}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          );
        }}
      </DataTable>
      <div ref={scrollbarRef} className="data-grid-scrollbar-container">
        <div />
      </div>
      <Pagination
        backwardText="Previous page"
        forwardText="Next page"
        itemsPerPageText="Items per page:"
        onChange={setPagination}
        page={page}
        pageSize={pageSize}
        pageSizes={pageSizes}
        itemRangeText={getItemRangeText}
        totalItems={data.length}
      />
    </div>
  );
};
