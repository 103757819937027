import { useContext, type FunctionComponent } from 'react';
import { Accordion, AccordionItem } from '@carbon/react';
import { AttributesContext } from '../../../providers/AttributesProvider';
import type { Attribute } from '../../../reducers/AttributesReducer';

const AttributeReview: FunctionComponent = () => {
  const { uploadData } = useContext(AttributesContext);
  return (
    <div className="AttributeReview">
      <Accordion
        size="md"
        className="FiltersContent__filters-container"
        id="filter-accordion-list"
        data-testid="attribute-review-accordion"
      >
        {uploadData?.attributes.map(
          ({ attribute_id, attribute, attribute_values }: Attribute) => {
            return (
              <div
                className="AttributeReview__accordion-wrapper"
                key={attribute_id}
              >
                <AccordionItem
                  title={
                    <div className="FiltersContent__filter-title">
                      <span className="AttributeReview__attribute">
                        {attribute}
                      </span>
                      <span className="AttributeReview__attribute-sku">
                        {`(${
                          Object.keys(attribute_values).filter(
                            (sku) => attribute_values[sku].value !== ''
                          ).length
                        } SKUs)`}
                      </span>
                    </div>
                  }
                  open
                  className="FiltersContent__filter-heading"
                >
                  {Array.from(
                    new Set(
                      Object.values(attribute_values)
                        .map((att) => (att as { value: string }).value)
                        .filter((value) => value !== '')
                    )
                  ).map((value) => (
                    <div className="AttributeReview__value" key={value}>
                      {value}
                    </div>
                  ))}
                </AccordionItem>
              </div>
            );
          }
        )}
      </Accordion>
    </div>
  );
};

export default AttributeReview;
