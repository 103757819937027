import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useMemo, useReducer } from 'react';
import {
  attributesReducer,
  initialAttributesState,
  type UploadData,
  type AttributesState,
  type UploadMetadata,
} from '../reducers/AttributesReducer';

import {
  RESET_ATTRIBUTES_UPLOAD_DATA,
  UPDATE_ATTRIBUTES_FILE_SPECS,
  UPDATE_ATTRIBUTES_UPLOAD_DATA,
  UPDATE_ATTRIBUTES_UPLOAD_METADATA,
} from '../constants/reducers';
import type { FileSpecs } from '../reducers/CustomGroupsReducer';

export interface AttributesContextType extends AttributesState {
  updateFileSpecs: (fileSpecs: Partial<FileSpecs>) => void;
  updateUploadData: (uploadData: UploadData) => void;
  updateUploadMetadata: (uploadMetadata: UploadMetadata) => void;
  resetUploadData: () => void;
}

interface AttributesProviderProps {
  children?: ReactNode;
}

export const AttributesContext = createContext<AttributesContextType | null>(
  null
);

const AttributesProvider: FunctionComponent<AttributesProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    attributesReducer,
    initialAttributesState
  );

  const updateFileSpecs = (fileSpecs: Partial<FileSpecs>) => {
    dispatch({ type: UPDATE_ATTRIBUTES_FILE_SPECS, fileSpecs });
  };

  const updateUploadData = (uploadData: UploadData) =>
    dispatch({ type: UPDATE_ATTRIBUTES_UPLOAD_DATA, uploadData });

  const updateUploadMetadata = (uploadMetadata: UploadMetadata) =>
    dispatch({ type: UPDATE_ATTRIBUTES_UPLOAD_METADATA, uploadMetadata });

  const resetUploadData = () =>
    dispatch({ type: RESET_ATTRIBUTES_UPLOAD_DATA });

  const attributesContext: AttributesContextType = useMemo(() => {
    return {
      uploadData: state.uploadData,
      fileSpecs: state.fileSpecs,
      uploadMetadata: state.uploadMetadata,
      updateFileSpecs,
      updateUploadData,
      updateUploadMetadata,
      resetUploadData,
    };
  }, [state]);

  return (
    <AttributesContext.Provider value={attributesContext}>
      {children}
    </AttributesContext.Provider>
  );
};

export default AttributesProvider;
