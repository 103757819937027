/* eslint-disable indent */
import { DateTime } from 'luxon';
import * as htmlToImage from 'html-to-image';
import { getSelectedKey, removeHexColourPrefix } from '.';
import { timePeriodOverlaps } from './DateUtils';
import { getNodeId } from '../components/ReportBuilder/utils/hierarchyUtils';
import apiRequest from '../api';
import { chartExportClick } from '../constants/posthog';
import type {
  ReportConfig,
  SwitcherContent,
  Visual,
  VisualData,
} from '../reducers/ReportReducer';
import { VisualType } from '../reducers/ReportReducer';
import axios from 'axios';
import posthog from 'posthog-js';
import { contrast } from 'chroma-js';
import { EXCEL_SHEET_NAME_LIMIT } from '../constants/metadata';
import type { UploadedDataValidationResponse } from '../types/apiTypes';
import type { ResponsePayload } from '../components/Fetch';
import type { GlobalSelection } from '../components/Report/ReportContent';

const sortCalendarGroups = (finalParameters) => {
  finalParameters['time_groups'].forEach((param) => {
    param['items']?.sort((a, b) =>
      a.id.localeCompare(b.id, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    );
  });
};

export const getReportData = (
  name: string,
  parameters: ReportParameters
): ReportData => {
  const populatedParamKeys = Object.keys(parameters).filter((key) => {
    return (parameters[key] as ReportParameter[]).length > 0;
  });
  const finalParameters = {};
  const parametersClone = structuredClone(parameters);

  populatedParamKeys.forEach((key) => {
    finalParameters[key] = (parametersClone[key] as ReportParameter[]).map(
      (param) => {
        if (param.items) {
          param.items = param.items.map(({ id, hierarchy_element }) => {
            return { id, hierarchy_element };
          });
        }
        if (param.name) {
          param.name = param.name.replace(/'/g, '');
        }
        return param;
      }
    );
  });

  if ('time_groups' in finalParameters) {
    sortCalendarGroups(finalParameters);
  }

  return { report_name: name, ...finalParameters };
};

export const getOverlap = (
  hasOverlap: boolean,
  currentOverlap: string[],
  dimension: DimensionName
): string[] => {
  if (hasOverlap) {
    return currentOverlap.includes(dimension)
      ? currentOverlap
      : [...currentOverlap, dimension];
  }
  return currentOverlap.filter((dim) => dim !== dimension);
};

export const formatNumber = (
  point: NumberPoint,
  options?: Intl.NumberFormatOptions
): string => {
  if (!point.value && point.value !== 0) {
    return '-';
  }

  const formatPercent = (fractionDigits: number, includePt = false) =>
    new Intl.NumberFormat('en-GB', {
      style: 'decimal',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      ...options,
    }).format(point.value * 100) + (includePt ? '% pt' : '%');

  switch (point.format) {
    case 'integer':
      return new Intl.NumberFormat('en-GB', {
        style: 'decimal',
        maximumFractionDigits: 0,
        ...options,
      }).format(point.value);

    case 'decimal2':
      return new Intl.NumberFormat('en-GB', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(point.value);

    case 'percent':
      return formatPercent(0);

    case 'percent1':
      return formatPercent(1);

    case 'percent2':
      return formatPercent(2);

    case 'percentpt':
      return formatPercent(0, true);

    case 'percent1pt':
      return formatPercent(1, true);

    case 'percent2pt':
      return formatPercent(2, true);

    case 'currency':
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: point.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
        ...options,
      }).format(point.value);

    case 'currency1':
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: point.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        ...options,
      }).format(point.value);

    case 'currency2':
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: point.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(point.value);

    default:
      return new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
        ...options,
      }).format(point.value);
  }
};

export const formatAxis = (point: NumberPoint) => {
  const { format, value, currency } = point;
  const absValue = Math.abs(value);
  const fractionOptions = {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  };

  switch (format) {
    case 'integer':
    case 'decimal2': {
      if (absValue >= 1000000) {
        const formattedNumber = formatNumber(
          { value: value / 1000000, format: 'integer' },
          fractionOptions
        );
        return formattedNumber + 'M';
      } else if (absValue >= 1000) {
        const formattedNumber = formatNumber(
          {
            value: value / 1000,
            format: 'integer',
          },
          fractionOptions
        );
        return formattedNumber + 'K';
      }
      return formatNumber(point, fractionOptions);
    }
    case 'currency':
    case 'currency1':
    case 'currency2': {
      if (absValue >= 1000000) {
        const formattedNumber = formatNumber(
          {
            value: value / 1000000,
            format: 'currency',
            currency,
          },
          fractionOptions
        );
        return formattedNumber + 'M';
      } else if (absValue >= 1000) {
        const formattedNumber = formatNumber(
          {
            value: value / 1000,
            format: 'currency',
            currency,
          },
          fractionOptions
        );
        return formattedNumber + 'K';
      }
      return formatNumber(point, fractionOptions);
    }
    default:
      return formatNumber(point, fractionOptions);
  }
};

export const formatExportableCell = (point: NumberPoint): string => {
  const getCurrencySymbol = (point: NumberPoint) => {
    const numberFormat = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: point.currency,
      currencyDisplay: 'narrowSymbol',
    });

    const parts = numberFormat.formatToParts(point.value);
    return parts.find(({ type }) => type === 'currency').value;
  };

  switch (point.format) {
    case 'integer':
      return '#,##0';
    case 'decimal2':
      return '#,##0.00';
    case 'percent':
      return '0%';
    case 'percent1':
      return '0.0%';
    case 'percent2':
      return '0.00%';
    case 'currency':
      return `${getCurrencySymbol(point)} #,##0`;
    case 'currency1':
      return `${getCurrencySymbol(point)} #,##0.0`;
    case 'currency2':
      return `${getCurrencySymbol(point)} #,##0.00`;
    default:
      return '0';
  }
};

export const mapExportableData = (rowData, headers) => {
  const getPoint = (rowValue, currency, format) => {
    return typeof rowValue === 'object'
      ? {
          format: rowValue.format,
          currency: rowValue.currency,
          value: rowValue.value === ' ' ? undefined : rowValue.value,
        }
      : {
          format,
          currency,
          value: rowValue === ' ' ? undefined : rowValue,
        };
  };
  const rows = rowData.map((row) => {
    return headers.map(({ key, currency, format }) => {
      const rowValue = row[key];
      const point = getPoint(rowValue, currency, format);
      const fillOption = rowValue?.background_colour && {
        fill: {
          fgColor: {
            rgb: removeHexColourPrefix(rowValue.background_colour),
          },
        },
      };
      const numberFormat = point.format && { z: formatExportableCell(point) };
      const cell = {
        v: point.value,
        t: point.format === 'string' || !point.format ? 's' : 'n',
        s: {
          ...fillOption,
          font: {
            color: {
              rgb: removeHexColourPrefix(rowValue?.colour) ?? '000000',
            },
            name: 'Noto Sans',
          },
          alignment: { horizontal: point.format ? 'right' : 'left' },
        },
        ...numberFormat,
      };
      return cell;
    });
  });
  const headerLabels = headers.map(({ header }) => ({
    v: header,
    t: 's',
    s: {
      fill: {
        fgColor: {
          rgb: 'e1f0ff',
        },
      },
      font: {
        color: { rgb: '00215d' },
        name: 'Noto Sans',
        bold: true,
      },
    },
  }));
  return [headerLabels, ...rows];
};

export const validateUploadData = async (
  dimension: string,
  entities: { entity_no: string | number }[],
  token: string,
  bannerId: number,
  groupId: number
) => {
  const lowerCaseDimension = dimension.toLowerCase();
  return await apiRequest<ResponsePayload<UploadedDataValidationResponse>>(
    `/configs/${bannerId}/user-groups/${groupId}/hierarchies/validate`,
    'POST',
    token,
    {
      dimension: lowerCaseDimension,
      entities,
    }
  );
};

export const getHierarchyApiUrl = (
  selectedHierarchy: Hierarchy,
  bannerId: number,
  groupId: number,
  userType: string
) => {
  const { id, key, user_type_enabled, user_group_enabled } = selectedHierarchy;
  if (key === 'time' && user_type_enabled && userType) {
    return `/metadata/${bannerId}/user-types/${userType}/${id}`;
  } else {
    return user_group_enabled
      ? `/metadata/${bannerId}/user-groups/${groupId}/${id}`
      : `/metadata/${bannerId}/${id}`;
  }
};

export const getAutoGeneratedName = (module: string) => {
  return `${module.toUpperCase()} ${DateTime.fromMillis(Date.now()).toFormat(
    'dd/MM HH:mm'
  )}`;
};

export const getParameterHierarchy = (
  parameters: ReportParameters,
  dimensions: Dimension[]
): Hierarchy[] => {
  if (dimensions.length === 0) {
    return [];
  }
  const filteredParams = filterExtraParams(parameters);
  const hierarchies = Object.values(filteredParams).flatMap((parameter) =>
    (parameter as ReportParameter[]).map((group) =>
      dimensions
        .flatMap(({ hierarchies }) => hierarchies)
        .find((item) => item.name === group.hierarchy_name)
    )
  );

  return Array.from(new Set(hierarchies));
};

export const fetchHierarchiesData = async (
  hierarchies: Hierarchy[],
  bannerId: number,
  groupId: number,
  token: string,
  userType: string
) => {
  const hierarchiesData = await Promise.all(
    hierarchies.map((hierarchy) =>
      apiRequest<ResponsePayload<HierarchyResponse>>(
        getHierarchyApiUrl(hierarchy, bannerId, groupId, userType),
        'GET',
        token
      )
    )
  );

  return hierarchiesData.map(({ data }) => data);
};

export const fetchMinimumRequirements = async (
  reportType: string,
  bannerId: number,
  token: string
) => {
  const response = await apiRequest<ResponsePayload<RuleSet>>(
    `/configs/${bannerId}/reports/${reportType}`,
    'GET',
    token
  );

  return response.data;
};

export const itemExistsInGroups = (
  item: HierarchyParam,
  groups: ReportParameter[]
): boolean => {
  for (const group of groups) {
    if (
      group.items.filter((groupItem) => {
        return groupItem.id === item.id;
      }).length > 0
    ) {
      return true;
    } else {
      for (const groupItem of group.items) {
        if (
          groupItem.children &&
          itemExistsInChildren(item, groupItem.children)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

export const itemExistsInChildren = (
  item: HierarchyParam,
  nodes?: HierNode[]
): boolean => {
  if (!nodes || nodes.length === 0) {
    return false;
  }

  const itemId = getNodeId(item);

  return nodes.some((node) => {
    const nodeId = getNodeId(node);
    return (
      nodeId === itemId ||
      (node.children && itemExistsInChildren(item, node.children))
    );
  });
};

export const numDistinctHierarchies = (
  selectedGroups: ReportParameter[]
): number => {
  const hierarchyIds = new Set(
    selectedGroups.map(({ hierarchy_name }) => hierarchy_name)
  );

  return hierarchyIds.size;
};

export const addChildrenToNodes = (
  groups: ReportParameter[],
  hierarchies: HierarchyResponse[],
  hierarchiesList: Hierarchy[]
): ReportParameter[] => {
  const editedGroups = groups.map((param) => {
    const hierarchyIndex = hierarchiesList.findIndex(
      (item) => item.name === param.hierarchy_name
    );

    param.hierarchy_name !== 'Custom product hierarchy' &&
      param.items?.forEach((nodeItem) => {
        if (!nodeItem.children) {
          const hierarchy = hierarchies[hierarchyIndex]?.hierarchy;
          if (typeof hierarchy !== 'string') {
            const hierarchyNode = searchDeepNode(hierarchy, nodeItem.id);
            nodeItem.level = hierarchyNode?.level;
            if (hierarchyNode?.children) {
              nodeItem.children = hierarchyNode.children;
            }
          }
        }
      });
    return param;
  });
  return editedGroups;
};

export const detectSpendBandsOverlap = (
  selectedGroups: ReportParameter[]
): boolean => {
  const ranges = selectedGroups.map(({ min, max }) => ({ min, max }));
  return ranges.some((currentRange, index) => {
    if (index < ranges.length - 1) {
      const nextRange = ranges[index + 1];
      if (currentRange.max > nextRange.min || !currentRange.max) {
        return true;
      }
    }
    return false;
  });
};

export const hasOverlap = (selectedGroups: ReportParameter[]): Overlap => {
  if (selectedGroups.length < 2) {
    return { hasOverlap: false, reason: '' };
  }

  const numHierarchies = numDistinctHierarchies(selectedGroups);

  if (numHierarchies > 1) {
    return { hasOverlap: true, reason: 'This selection crosses hierarchies.' };
  }

  for (const thisGroup of selectedGroups) {
    const otherGroups = selectedGroups.filter(
      (otherGroup) => otherGroup !== thisGroup
    );
    if (timePeriodOverlaps(thisGroup, otherGroups)) {
      return {
        hasOverlap: true,
        reason: 'Time periods in this selection are overlapping.',
      };
    } else if (
      thisGroup.hierarchy_element === 'basket_amt' &&
      detectSpendBandsOverlap(selectedGroups)
    ) {
      return {
        hasOverlap: true,
        reason: 'Transaction spend bands in this selection are overlapping.',
      };
    } else {
      if (thisGroup.items) {
        for (const item of thisGroup.items) {
          if (itemExistsInGroups(item, otherGroups)) {
            return {
              hasOverlap: true,
              reason: 'Items in this selection are overlapping.',
            };
          }
        }
      }
    }
  }

  return {
    hasOverlap: false,
    reason: '',
  };
};

export const getSelectableOptions = (
  allDropdowns: DropdownConfig[],
  dropdownId: number,
  selectionKey: string
): string[] => {
  if (!selectionKey) {
    return [];
  }
  const dropdown = allDropdowns.find((dd) => dd.id === dropdownId);

  const selectableOptions = dropdown.options.filter((opt) => {
    if (!dropdown.linkedDropdowns) return true;

    const linkedSelection = dropdown.linkedDropdowns.reduce(
      (acc, linkedDropdownId) => {
        const selectedKey = getSelectedKey(
          allDropdowns,
          linkedDropdownId,
          selectionKey
        );
        acc[linkedDropdownId] = selectedKey;
        return acc;
      },
      {}
    );

    return Object.keys(linkedSelection).every((key) => {
      if (!opt.linkedOptions) return true;

      return opt.linkedOptions[key].includes(linkedSelection[key]);
    });
  });

  return selectableOptions.map(({ label }) => label);
};

export const getFirstDropdownSelectionKey = (
  dropdownConfig: DropdownConfig[]
) => {
  const keys = dropdownConfig.map(({ options }) =>
    options.map(({ key }) => key)
  );
  const initialKey = keys.map((key) => key[0]).join('_') || '1';

  const firstSelectableKeys = dropdownConfig.map((dropdown) => {
    const selectable = getSelectableOptions(
      dropdownConfig,
      dropdown.id,
      initialKey
    );
    return dropdown.options.find((opt) => opt.label === selectable[0])?.key;
  });

  return firstSelectableKeys.join('_') || '1';
};

export const getSVGPath = (
  d: d3.HierarchyPointNode<DendrogramNode>,
  width: number
): string => {
  const dx = width - d.data.dist * width;
  const dy = d.x;
  const parentX =
    d.parent.data.dist !== undefined ? width - d.parent.data.dist * width : 0;
  const parentY = d.parent ? d.parent.x : 0;
  const length = dx - parentX;
  const height = dy - parentY;

  return `M ${parentX === 0 ? 4 : parentX} ${parentY} l 0 ${height} l ${
    parentX === 0 ? length - 4 : length
  } 0`;
};

export const filterNodes = (node: HTMLElement): boolean => {
  const exclusionClasses = [
    'hide-in-export',
    'chart-container-actions',
    'kpi-tree-controls',
    'cdt-legend',
  ];
  return !exclusionClasses.some((className) =>
    node.classList?.contains(className)
  );
};

export const getDataUrl = async (ref, type: 'png' | 'svg') => {
  if (type === 'png') {
    return await htmlToImage.toPng(ref, {
      backgroundColor: 'white',
      filter: filterNodes,
    });
  } else {
    return await htmlToImage.toSvg(ref, {
      backgroundColor: 'white',
      filter: filterNodes,
    });
  }
};

export const clickDownload = (
  href: string,
  fileName: string,
  type: 'svg' | 'png' | 'xlsx',
  userId: string,
  groupId: number,
  origin: string
) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.${type}`;
  link.click();
  posthog.capture(chartExportClick, {
    payload: {
      type,
    },
    userId,
    groupId,
    origin,
  });
};

export const handleExport = async (
  elements: HTMLDivElement[],
  fileName: string,
  type: 'png' | 'svg',
  userId: string,
  groupId: number,
  origin: string
) => {
  document.body.classList.add('exporting');
  const exportElement = document.createElement('div');
  exportElement.classList.add('chart-export-wrapper');
  elements.forEach((el) => {
    if (el) {
      exportElement.appendChild(el.cloneNode(true));
    }
  });

  document.body.append(exportElement);
  const href = await getDataUrl(exportElement, type);
  document.body.removeChild(exportElement);
  clickDownload(href, fileName, type, userId, groupId, origin);
  document.body.classList.remove('exporting');
};

export const handleServerExport = async (
  element: HTMLDivElement | null,
  fileName: string,
  userId: string,
  groupId: number,
  token: string,
  origin: string
) => {
  const url = `${process.env.REACT_APP_API_URL}/reports/images`;
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Content-Type': 'text/html',
  };
  try {
    const response = await axios.request({
      url,
      headers,
      method: 'POST',
      data: element.innerHTML,
    });
    const imageLink = response.data.url;
    fetch(imageLink, {
      method: 'GET',
      headers: {},
    }).then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const href = window.URL.createObjectURL(new Blob([buffer]));
        clickDownload(href, fileName, 'png', userId, groupId, origin);
      });
      return true;
    });
  } catch {
    return false;
  }
};

export const getLeftOffset = (
  refs: Array<HTMLDivElement | null>,
  index: number
): number => {
  const previousColumns = refs.slice(0, index);
  const totalWidth = previousColumns.reduce(
    (prev, curr) => prev + (curr?.clientWidth ?? 0),
    0
  );
  return totalWidth;
};

export const getReportModuleAndSection = (
  reportModules: ReportSection[],
  section: string,
  moduleId: string
) => {
  if (reportModules?.length) {
    return reportModules
      .find((module) => module.section === section)
      .reports.find((report) => report.url_route === moduleId);
  } else {
    return null;
  }
};

export const searchDeepNode = <T extends HierNode>(
  array: T[],
  itemId: string
): T | null => {
  let element = null;
  const search = (node: T) => {
    if (node.id === itemId) {
      element = node;
    } else if (Array.isArray(node.children)) {
      node.children.forEach(search);
    }
  };
  array?.forEach(search);
  return element;
};

export const getSearchableColumnIds = (
  headerData: Header[],
  searchableColumns: number[]
): string[] =>
  headerData.reduce((acc, headerItem) => {
    if (searchableColumns.includes(headerItem.id)) {
      acc.push(headerItem.key);
    }
    return acc;
  }, []);

export const getFilteredRows = (
  cellsById: Record<string, CarbonCell<string>>,
  columnIds: string[] | null,
  searchQuery: string
): string[] => {
  const newRowIds = Object.values(cellsById)
    .filter(({ info }) => {
      return !columnIds || columnIds.includes(info.header);
    })
    .reduce((acc, { value, id }) => {
      const formattedValue = getFormattedValue(value);
      if (formattedValue.toLowerCase().includes(searchQuery.toLowerCase())) {
        const rowKey = id.split(':')[0];
        acc[rowKey] = true;
      }
      return acc;
    }, {});
  return Object.keys(newRowIds);
};

export const addAndRemoveItems = (
  existingItems: Array<string>,
  newItems: Array<string>
): Array<string> => {
  if (existingItems.length === 0) {
    return Array.from(new Set(newItems));
  }

  const finalItems = [...existingItems, ...newItems];
  const duplicates = newItems.reduce((prev, curr) => {
    if (existingItems.includes(curr)) {
      prev.push(curr);
    }
    return prev;
  }, []);

  return finalItems.filter((item) => !duplicates.includes(item));
};

export const getFormattedValue = (
  value: string | NumberPoint | Point<string>
) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value.value === 'string') {
    return value.value;
  }

  return formatNumber(value as NumberPoint);
};

export const getTemplateIdForReport = (
  reportModules: ReportSection[],
  url_route: string
): number => {
  const report = reportModules
    .flatMap((section) => section.reports)
    .find((report) => report.url_route === url_route);

  return report?.template_id || -1;
};

export const isValidReportName = (reportName: string) => {
  return reportName.trim().length > 0;
};

export const filterExtraParams = (parameters: ReportParameters) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { analytic_engine_params, overlap, ...filteredParameters } = parameters;
  return filteredParameters;
};

export const shouldShowExportAll = (
  reportConfig: ReportConfig,
  tabIndex: number,
  reportTemplateId: string
): boolean => {
  const { configuration } = reportConfig;
  const { switchers, visuals } = configuration;
  const visualsOnTab = switchers?.[reportTemplateId][tabIndex]?.visualContent;
  const grids = visuals[reportTemplateId].filter(
    ({ id, type, container, visuals }) => {
      const typeMatching = container
        ? findVisual(visuals, ({ type }) => !!type?.match('grid'))
        : type?.match('grid');
      return visualsOnTab?.includes(id) && typeMatching;
    }
  );
  return visualsOnTab?.length - grids.length > 1;
};

export interface MergedHeader extends Pick<Header, 'key' | 'header'> {
  configKeys: string[];
}

interface DropdownData {
  [key: string]: Record<string, string>;
}

interface HeaderMap {
  [key: MergedHeader['key']]: Pick<MergedHeader, 'header' | 'configKeys'>;
}

interface WorksheetData {
  headers: MergedHeader[];
  rows: {
    [x: string]: unknown;
  }[];
  label?: string;
}

export const getAllDataTableOptions = <T>(
  visualData: VisualData<T>
): WorksheetData[] => {
  const dropdownData = visualData.dropdownConfig.reduce(
    (acc, dropdown, index) => {
      acc[index] = {};
      dropdown.options.forEach((option) => {
        acc[index][option.key] = option.label;
      });
      return acc;
    },
    {} as DropdownData
  );

  const getCellKey = (key: string, headers: MergedHeader[]) => {
    return (
      headers.find(({ configKeys }) => configKeys.includes(key))?.key ?? key
    );
  };

  const getHeaders = (headers: [string, Header[]][]) => {
    const originalHeaders = headers.flatMap(([headerKey, headerData]) => {
      return headerData.map(({ key: columnKey, header }) => ({
        key: `${header}_${columnKey}`,
        configKey: `${headerKey}_${columnKey}`,
        header,
      }));
    });

    const headerMap = visualData.dropdownConfig
      .map(({ id, label }) => ({
        key: `dropdown_option_${id}`,
        configKey: `dropdown_option_${id}`,
        header: label,
      }))
      .concat(originalHeaders)
      .reduce((acc, item) => {
        const configKeys = acc[item.key]?.configKeys ?? [];
        acc[item.key] = {
          configKeys: [...configKeys, item.configKey],
          header: item.header,
        };
        return acc;
      }, {} as HeaderMap);

    return Object.entries(headerMap).map(([key, value]) => ({
      ...value,
      key,
    }));
  };

  const getRows = (rows: [string, T[]][], headers: MergedHeader[]) => {
    return rows.flatMap(([dropdownKey, rows]) => {
      const dropdownConfig = dropdownKey.split('_');
      const newRows = rows
        .map((row) =>
          Object.entries(row).reduce((acc, item) => {
            const [key, value] = item;
            const cellKey = getCellKey(`${dropdownKey}_${key}`, headers);
            acc[cellKey] = value;
            return acc;
          }, {} as Record<string, unknown>)
        )
        .map((row) => {
          const additionalCells = visualData.dropdownConfig.reduce(
            (acc, { id }, index) => {
              acc[`dropdown_option_${id}`] =
                dropdownData[index][dropdownConfig[index]];
              return acc;
            },
            {} as Record<string, unknown>
          );
          return {
            ...additionalCells,
            ...row,
          };
        });
      return newRows;
    });
  };

  const generateCommonWorksheet = () => {
    const headers = getHeaders(Object.entries(visualData.headers));
    const rows = getRows(Object.entries(visualData.rows), headers);

    return [
      {
        headers,
        rows,
      },
    ];
  };

  const splitDropdown = visualData.dropdownConfig.find(
    ({ tabExport }) => tabExport
  );

  const generateSplitWorksheets = () => {
    const worksheets = splitDropdown.options.map(({ key, label }) => {
      const headers = getHeaders(
        Object.entries(visualData.headers).filter(([headerKey]) => {
          const configKey = headerKey.split('_')[0];
          return configKey === key.toString();
        })
      );
      const rows = getRows(
        Object.entries(visualData.rows).filter(([dropdownKey]) => {
          const configKey = dropdownKey.split('_')[0];
          return configKey === key.toString();
        }),
        headers
      );

      return {
        label: label.replaceAll(/[:\\/?*[\]]/gm, '_'),
        headers,
        rows,
      };
    });
    return worksheets;
  };

  return !splitDropdown ? generateCommonWorksheet() : generateSplitWorksheets();
};

export const getVisibleColumns = (
  newIds: number[],
  oldIds: number[],
  current: number[]
) => {
  const toRemove = oldIds.filter((id) => !newIds.includes(id));

  return Array.from(
    new Set([...newIds, ...current.filter((id) => !toRemove.includes(id))])
  );
};

export const checkGetVisualData = (
  visualData: VisualData | { [key: string]: DendrogramNode },
  dropdownKey: string,
  type: string
): boolean => {
  const excludedTypes = [
    'cdt-grid',
    'dendrogram',
    'cdt-dendrogram',
    'product-summary',
  ];
  if (excludedTypes.includes(type)) {
    return true;
  } else {
    return ['vennData', 'seriesData', 'rows', 'cards', 'summary'].some(
      (data) => visualData[data]?.[dropdownKey]
    );
  }
};

export const getSKUGrid = async (
  apiUrl: string,
  token: string,
  isReportSplit: boolean
) => {
  const response = await apiRequest<ResponsePayload<VisualData>>(
    `${apiUrl}${isReportSplit ? '/config' : ''}`,
    'GET',
    token
  );

  return response.data;
};

export const getSkuGridApiUrl = (apiUrl: string) => {
  const apiUrlPath = apiUrl.split('/');
  apiUrlPath.splice(apiUrlPath.length - 1, 0, 'sku-lvl');
  const skuApiUrl = apiUrlPath.join('/');
  return skuApiUrl;
};

export const getDataFileKey = (dropdownKey: string) =>
  dropdownKey.split('_').join('-');

export const isTabDisabled = (
  tab: SwitcherContent,
  visuals: Record<string, Visual[]>,
  visualsData: { [key: string]: VisualData },
  reportTemplateId: string
) => {
  if (tab.name === 'CDT') {
    const gridId = findVisualByType(
      visuals[reportTemplateId],
      VisualType.CDT_GRID
    )?.id;
    return gridId ? !visualsData[gridId] : false;
  }
  return false;
};

export const getTextColor = (backgroundColor: string): string => {
  return contrast(backgroundColor, '#fff') >= 4.5 ? '#fff' : '#0A0909';
};

export const getPathUrlParam = (url: string): string => {
  const searchParams = new URLSearchParams(url.slice(url.indexOf('?')));

  return searchParams.get('path');
};

export const getReportApiUrl = (visualApiUrl: string): string =>
  getPathUrlParam(visualApiUrl).split('/').slice(0, -1).join('/');

export const getTaskIdFromUrl = (visualApiUrl: string): string =>
  getPathUrlParam(visualApiUrl).split('/')[1];

export const getHomepageConfigUrl = (
  apiUrl: string,
  bannerId: number,
  groupId: number,
  isStaticData?: boolean
): string =>
  `/homepage/user-groups/${groupId}/${
    isStaticData ? 'static-data' : 'data'
  }?path=${bannerId}/${getPathUrlParam(apiUrl)}`;

export const getHomepageFilePath = (apiUrl: string, bannerId: number): string =>
  `/homepage/${bannerId}?path=${getPathUrlParam(apiUrl)}`;

export const getGenAIConfigFilePath = (
  reportUrl: string,
  dropdownSelection: { [key: string]: string }
): string =>
  reportUrl
    .substring(1)
    .replace('?path=', '/')
    .concat(`/${getDataFileKey(Object.values(dropdownSelection)[0])}`);

export const getAllKeysCombinations = (keys: string[][]): string[][] => {
  const getKeys = (
    arr: string[][],
    dropdownIndex: number,
    current: string[]
  ) => {
    if (dropdownIndex === keys.length) {
      result.push(current);
      return;
    }
    keys[dropdownIndex].forEach((key) => {
      getKeys(arr, dropdownIndex + 1, current.concat(key));
    });
  };

  const result: string[][] = [];
  getKeys(keys, 0, []);
  return result;
};

export const exportAllTabsConfig = (
  dropdownConfig: DropdownConfig[],
  name: string,
  globalSelections?: GlobalSelection[]
) => {
  const tabExportDropdownIndex = dropdownConfig.findIndex(
    ({ tabExport }) => tabExport
  );
  const tabExportDropdown = dropdownConfig[tabExportDropdownIndex];
  const dropdowns = globalSelections?.some(
    ({ isExportUniqueKey }) => isExportUniqueKey
  )
    ? dropdownConfig.map((dropdown) => {
        const globalSelectionItem = globalSelections.find(
          ({ label, isExportUniqueKey }) =>
            label === dropdown.label && isExportUniqueKey
        );
        if (globalSelectionItem) {
          return {
            ...dropdown,
            options: [{ key: globalSelectionItem.selectedKey }],
          };
        } else {
          return dropdown;
        }
      })
    : dropdownConfig;

  const keys = dropdowns.map(({ options, tabExportKeys }) =>
    tabExportKeys
      ? tabExportKeys.map((key) => key.toString())
      : options.map(({ key }) => key.toString())
  );
  const allPossibleKeys = getAllKeysCombinations(keys);

  const getTabLabel = (label: string) => {
    return label?.length > EXCEL_SHEET_NAME_LIMIT
      ? label.substring(0, EXCEL_SHEET_NAME_LIMIT - 1) + '…'
      : label;
  };

  const tabs = tabExportDropdown
    ? tabExportDropdown.options.map(({ label, key }) => {
        return {
          label: getTabLabel(label),
          keys: allPossibleKeys
            .filter(
              (keyCombination) =>
                keyCombination[tabExportDropdownIndex] === key.toString()
            )
            .map((keyCombination) => keyCombination.join('-')),
        };
      })
    : [
        {
          label: getTabLabel(name),
          keys: allPossibleKeys.map((keyCombination) =>
            keyCombination.join('-')
          ),
        },
      ];
  return tabs;
};

export const serverDataGridExport = async ({
  visualApiUrl,
  reportName,
  dropdownConfig,
  globalSelections,
  reportId,
  token,
  bannerId,
  groupId,
  visualId,
}: {
  visualApiUrl: string;
  reportName: string;
  dropdownConfig: DropdownConfig[];
  globalSelections: GlobalSelection[];
  reportId: string;
  token: string;
  bannerId: number;
  groupId: number;
  visualId: string | number;
}) => {
  const reportPath = getPathUrlParam(visualApiUrl);
  const tabs = exportAllTabsConfig(
    dropdownConfig,
    reportName,
    globalSelections
  );
  const payload = {
    run_id: reportId,
    tabs,
    path: reportPath,
    name: reportName,
    id: visualId,
  };
  await apiRequest(
    `/reports/${bannerId}/user-groups/${groupId}/export-grid`,
    'POST',
    token,
    payload
  );
};

export const capitalizeFirstLetter = (str: string): string => {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const hasSkuGrid = (
  visuals: Visual[],
  visualData: VisualData,
  skuLevel: string | undefined
): boolean => {
  if (skuLevel !== 'yes' || !visualData) {
    return false;
  }

  const cellGridVisuals = filterVisuals(
    visuals,
    ({ type }) => type === VisualType.CELL_GRID
  );

  return cellGridVisuals.length === 1 || !!visualData.hasChildGrid;
};

export const filterVisuals = (
  visuals: Visual[],
  callback: (visual: Visual) => boolean
): Visual[] => {
  return visuals.reduce((acc, visual) => {
    const { container, visuals: nestedVisuals } = visual;
    if (container) {
      return [...acc, ...filterVisuals(nestedVisuals, callback)];
    } else if (callback(visual)) {
      return [...acc, visual];
    }
    return acc;
  }, [] as Visual[]);
};

export const findVisual = (
  visuals: Visual[],
  callback: (visual: Visual) => boolean
): Visual | undefined => {
  for (const visual of visuals) {
    const { container, visuals } = visual;
    if (container) {
      const nestedVisual = findVisual(visuals, callback);
      if (nestedVisual) {
        return nestedVisual;
      }
    } else {
      if (callback(visual)) {
        return visual;
      }
    }
  }
};

export const findVisualById = (visuals: Visual[], id: number) => {
  const matcher = (visual: Visual) => visual.id === id;

  return findVisual(visuals, matcher);
};

export const findVisualByType = (visuals: Visual[], type: VisualType) => {
  const matcher = (visual: Visual) => visual.type === type;

  return findVisual(visuals, matcher);
};

export const visualResponsive = (vis: Visual): boolean => {
  return (
    vis.type !== VisualType.CDT_DENDROGRAM && vis.type !== VisualType.CDT_GRID
  );
};

export const getCustomStyles = (
  vis: Visual
): {
  marginLeft?: number;
  marginRight?: number;
} => {
  if ([VisualType.DENDROGRAM, VisualType.CDT_DENDROGRAM].includes(vis.type)) {
    return { marginRight: 0 };
  }
  if ([VisualType.DENDRO_GRID, VisualType.CDT_GRID].includes(vis.type)) {
    return { marginLeft: 0 };
  }
};

export const getExportedGridId = (
  id: string | number,
  globalSelections: GlobalSelection[] | undefined
): string | number => {
  const globalSelectionKey = globalSelections
    ?.filter(({ isExportUniqueKey }) => isExportUniqueKey)
    .map(({ selectedKey }) => selectedKey)
    .join('_');
  return globalSelectionKey ? `${id}_${globalSelectionKey}` : id;
};
