import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@carbon/react';
import CustomAttributesSuccess from '../../../assets/custom-groups/custom_group_success.svg';

const AttributeUploadSuccess: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="AttributeUploadSuccess">
      <img
        src={CustomAttributesSuccess}
        alt="Custom Attributes Upload Success"
      />
      <div className="AttributeUploadSuccess__title">Success!</div>
      <div className="AttributeUploadSuccess__text">
        Your attribute values have been updated successfully
      </div>
      <div className="AttributeUploadSuccess__actions">
        <Button
          kind="primary"
          type="submit"
          onClick={() => {
            navigate('/attribute-manager');
          }}
          data-testid="view-attributes-button"
          size="md"
        >
          View Attributes
        </Button>
      </div>
    </div>
  );
};

export default AttributeUploadSuccess;
