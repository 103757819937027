/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Download, Maximize, Minimize, View } from '@carbon/icons-react';
import {
  IconButton,
  InlineLoading,
  Checkbox,
  OverflowMenu,
  Dropdown,
  Button,
} from '@carbon/react';
import type { PlotSeriesDataLabelsOptions } from 'highcharts';
import { AppContext } from '../../providers/AppProvider';
import { ChartContext } from '../../providers/ChartProvider';
import { ReportContext } from '../../providers/ReportProvider';
import type {
  FileData,
  VisualData,
  VisualType,
} from '../../reducers/ReportReducer';
import { getFormat, getYAxesData } from '../../utils/chartUtils';
import { handleServerExport } from '../../utils/reportUtils';
import '../../styles/components/chart.scss';
import { useAuth0 } from '@auth0/auth0-react';
import ExportOverflow from './ExportOverflow';
import {
  CHART_KEY,
  SHARED_DROPDOWN_KEY,
  VISUAL_CONTAINER_KEY,
} from '../../constants/values';
import usePosthog from '../../utils/posthog';
import {
  dataLabelsToggle,
  chartShowSecondaryAxisClick,
} from '../../constants/posthog';
import Tooltip from '../Tooltip';
import ChartDualYAxisIcon from '../../assets/icons/ChartDualYAxisIcon';
import { getSelectedItem } from '../../utils';
import { customChartTooltip } from '../Report/TooltipFormatters';
import { formatUTCDate } from '../../utils/DateUtils';
import CardHeader from '../Cards/CardHeader';

interface ChartContainerProps {
  id: number;
  activeMeasure?: Measure;
  showToggle?: boolean;
  showExport?: boolean;
  showExpand?: boolean;
  useServerExportService?: boolean;
  visualType: VisualType;
  visualData?: VisualData | FileData;
  dropdownKey?: string;
  visualHeader?: VisualHeader;
  tab?: number | string;
  isSecondaryAxisVisible?: boolean;
  measureFormats?: VisualData['measureFormats'];
  children?: JSX.Element | JSX.Element[];
}

const ChartContainer: FunctionComponent<ChartContainerProps> = ({
  id,
  activeMeasure,
  showExpand = true,
  showToggle = true,
  showExport = true,
  useServerExportService = false,
  visualType,
  visualData,
  dropdownKey,
  visualHeader,
  tab,
  isSecondaryAxisVisible,
  measureFormats,
  children,
}) => {
  const { groupId, user } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuth0();
  const {
    visualRefs,
    fullscreenVisual,
    secondaryDropdownSelections,
    toggleVisualFullscreen,
    toggleSecondaryAxis,
    updateSecondaryAxisSelection,
    setVisualControlsRef,
    reportConfig: { report_name, url_route, report_type, report_version },
  } = useContext(ReportContext);
  const isReportSplit = report_version === 'v2';
  const posthogEvent = usePosthog();
  const dropdowns = isReportSplit
    ? (visualData as FileData)?.visualDropdownConfig
    : visualData?.dropdownConfig;
  const exportedFileName =
    report_name ?? `OneViu export ${formatUTCDate(new Date().toISOString())}`;
  const controlsRef = useRef<HTMLDivElement | null>(null);

  const secondaryAxisOptions = useMemo(() => {
    const selectedMeasure = dropdownKey?.split('_')[0];
    return dropdowns
      ?.find(({ id }) => id === visualData.measureDropdownId)
      .options.filter(({ key }) => key.toString() !== selectedMeasure);
  }, [dropdownKey]);

  const { chartOptions, updateChartOptions, updateMeasure } =
    useContext(ChartContext);

  const secondarySelectionId = `chart-${id}`;

  const secondaryAxis = useMemo(
    () =>
      secondaryDropdownSelections?.[secondarySelectionId] ?? {
        isActive: false,
      },
    [secondaryDropdownSelections]
  );

  const [exporting, setExporting] = useState(false);
  const [isChartOptionsOpen, setIsChartOptionsOpen] = useState(false);

  const dataLabels = (chartOptions?.plotOptions?.series
    ?.dataLabels as Highcharts.DataLabelsOptions) || { enabled: false };

  useEffect(() => {
    updateMeasure(activeMeasure);
    if (showToggle) {
      updateChartOptions({
        ...chartOptions,
        plotOptions: {
          ...chartOptions?.plotOptions,
          series: {
            ...chartOptions?.plotOptions?.series,
            dataLabels: {
              enabled: dataLabels.enabled,
              inside: true,
              allowOverlap: true,
              formatter: function () {
                return getFormat({
                  point: this,
                  measure: activeMeasure,
                  secondaryMeasure:
                    visualData?.measureFormats?.[
                      secondaryAxis.selection?.numberFormat
                    ],
                  measureFormats,
                });
              },
            },
          },
        },
      });
    }
  }, [activeMeasure, secondaryAxis.selection?.numberFormat]);

  useEffect(() => {
    if (visualData) {
      const { yAxesData, series } = getYAxesData({
        data: visualData,
        secondaryAxis,
        dropdownKey,
        measure: activeMeasure,
        isReportSplit,
        isSecondaryAxisVisible,
      });
      updateChartOptions({
        ...chartOptions,
        yAxis: yAxesData,
        series,
        tooltip: secondaryAxis.selection
          ? {
              formatter: function () {
                return customChartTooltip({
                  tooltipType: visualData.tooltipType,
                  measure: activeMeasure,
                  context: this,
                  secondaryMeasure:
                    visualData.measureFormats[
                      secondaryAxis.selection.numberFormat
                    ],
                  measureFormats,
                });
              },
            }
          : {},
        plotOptions: {
          ...chartOptions?.plotOptions,
          series: {
            ...chartOptions?.plotOptions?.series,
            dataLabels: {
              enabled: (
                chartOptions?.plotOptions?.series
                  ?.dataLabels as PlotSeriesDataLabelsOptions
              )?.enabled,
              inside: true,
              allowOverlap: true,
              formatter: function () {
                return getFormat({
                  point: this,
                  measure: activeMeasure,
                  secondaryMeasure:
                    visualData?.measureFormats?.[
                      secondaryAxis.selection?.numberFormat
                    ],
                  measureFormats,
                });
              },
            },
          },
        },
      });
    }
  }, [secondaryAxis, dropdownKey, activeMeasure]);

  const handleExpand = () => {
    toggleVisualFullscreen(id === fullscreenVisual ? null : id);
  };

  const serverExport = async () => {
    setExporting(true);
    const token = await getAccessTokenSilently();
    await handleServerExport(
      visualRefs.current[`${CHART_KEY}-${id}`],
      exportedFileName,
      user.id,
      groupId,
      token,
      url_route
    );
    setExporting(false);
  };

  const exportButton = () => {
    return useServerExportService ? (
      <Button
        kind="ghost"
        data-testid="download-chart"
        iconDescription="Download chart"
        renderIcon={Download}
        onClick={() => serverExport()}
        hasIconOnly
        tooltipPosition={
          report_type === 'Customer Decision Tree' ? 'left' : 'top'
        }
        size="md"
      />
    ) : (
      <ExportOverflow
        elements={[
          visualRefs.current[`${SHARED_DROPDOWN_KEY}-${tab}`],
          visualRefs.current[`${VISUAL_CONTAINER_KEY}-${id}`],
        ]}
        report_name={visualHeader?.title ?? exportedFileName}
        alignTooltip="top"
        size="md"
      />
    );
  };

  const toggleChartOptionsMenu = (event: PointerEvent) => {
    event.stopPropagation();
    setIsChartOptionsOpen(!isChartOptionsOpen);
  };

  useEffect(() => {
    const secondaryAxisTitle = document.querySelector('#axis-edit-icon');
    secondaryAxisTitle?.addEventListener('click', toggleChartOptionsMenu);

    return () => {
      secondaryAxisTitle?.removeEventListener('click', toggleChartOptionsMenu);
    };
  }, [secondaryAxis.selection, toggleChartOptionsMenu]);

  useEffect(() => {
    if (dropdownKey) {
      const selectedMeasure = getSelectedItem(
        visualData?.measureDropdownId,
        dropdownKey,
        dropdowns
      );
      if (secondaryAxis.selection?.label === selectedMeasure) {
        updateSecondaryAxisSelection(
          secondarySelectionId,
          secondaryAxisOptions[0]
        );
      }
    }
  }, [secondaryAxisOptions]);

  useEffect(() => {
    if (controlsRef) {
      setVisualControlsRef(id, controlsRef);
    }
  }, []);

  return (
    <div
      data-testid={`chart-container-${visualType}`}
      className="chart-container"
    >
      <div className="ChartContainer__header">
        {visualHeader?.title && <CardHeader {...visualHeader} />}
        <div className="chart-container-actions">
          <Tooltip description="Show secondary axis" align="top">
            {isSecondaryAxisVisible && (
              <OverflowMenu
                renderIcon={() => <ChartDualYAxisIcon />}
                className="ChartContainer__chart-options-menu"
                data-testid="secondary-axis-options"
                focusTrap={false}
                menuOptionsClass="ChartContainer__chart-options-content"
                flipped
                ariaLabel="secondary-axis-options"
                open={isChartOptionsOpen}
                onOpen={() => setIsChartOptionsOpen(true)}
                onClose={() => setIsChartOptionsOpen(false)}
                data-floating-menu-container
                size="md"
              >
                <Checkbox
                  labelText="Show Secondary Axis"
                  id="toggle-secondary-axis"
                  data-testid="toggle-secondary-axis"
                  checked={secondaryAxis.isActive}
                  onChange={(_, { checked }) => {
                    toggleSecondaryAxis(secondarySelectionId, checked);
                    if (!secondaryAxis.selection) {
                      updateSecondaryAxisSelection(
                        secondarySelectionId,
                        secondaryAxisOptions[0]
                      );
                    }
                  }}
                  onClick={() =>
                    posthogEvent(chartShowSecondaryAxisClick, {
                      reportType: url_route,
                    })
                  }
                />
                {secondaryAxis.isActive && (
                  <Dropdown
                    className="ChartContainer__chart-options-dropdown"
                    id="secondary-axis-selection"
                    data-testid="secondary-axis-selection"
                    label=""
                    items={secondaryAxisOptions.map(({ label }) => label)}
                    selectedItem={secondaryAxis.selection.label}
                    size="sm"
                    onChange={({ selectedItem }) => {
                      updateSecondaryAxisSelection(
                        secondarySelectionId,
                        secondaryAxisOptions.find(
                          ({ label }) => selectedItem === label
                        )
                      );
                    }}
                  />
                )}
              </OverflowMenu>
            )}
          </Tooltip>
          {showToggle && (
            <IconButton
              kind="ghost"
              renderIcon={View}
              data-testid="toggle-data-labels"
              iconDescription="Toggle data labels"
              onClick={() => {
                updateChartOptions({
                  ...chartOptions,
                  plotOptions: {
                    series: {
                      dataLabels: {
                        enabled: !dataLabels.enabled,
                        inside: true,
                        allowOverlap: true,
                        formatter: function () {
                          return getFormat({
                            point: this,
                            measure: activeMeasure,
                            secondaryMeasure:
                              visualData?.measureFormats?.[
                                secondaryAxis.selection?.numberFormat
                              ],
                            measureFormats,
                          });
                        },
                      },
                    },
                  },
                });
                posthogEvent(dataLabelsToggle, {
                  enabled: !dataLabels.enabled,
                  reportType: url_route,
                });
              }}
              size="md"
              hasIconOnly
              tooltipPosition="top"
            />
          )}
          {showExport &&
            (exporting ? (
              <InlineLoading
                data-testid="exporting-file"
                className="exporting-file"
              />
            ) : (
              exportButton()
            ))}
          {showExpand && (
            <IconButton
              kind="ghost"
              data-testid="expand-chart"
              renderIcon={fullscreenVisual === id ? Minimize : Maximize}
              iconDescription={
                fullscreenVisual === id
                  ? 'Collapse Full Screen'
                  : 'Expand Full Screen'
              }
              onClick={handleExpand}
              hasIconOnly
              tooltipPosition={fullscreenVisual !== null ? 'left' : 'top'}
              size="md"
            />
          )}
        </div>
      </div>
      <div ref={controlsRef} />

      <div
        className="chart-container-contents"
        id={`chart-${id}`}
        ref={(el) => (visualRefs.current[`${CHART_KEY}-${id}`] = el)}
      >
        {children}
      </div>
    </div>
  );
};

export default ChartContainer;
