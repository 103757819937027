import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReportContext } from '../../../providers/ReportProvider';
import { SHARED_DROPDOWN_KEY } from '../../../constants/values';
import { homepageSharedDropdownClick } from '../../../constants/posthog';
import usePosthog from '../../../utils/posthog';
import type { UpdateDropdownSelectionParameters } from './VisualDropdown';
import VisualDropdown from './VisualDropdown';
import type { GlobalSelection } from '../ReportContent';

interface SharedDropdownsProps {
  tab: number | string;
  visualIds: number[];
  globalSelections?: GlobalSelection[];
  hideLabels?: boolean;
  optionalDropdowns?: DropdownConfig[];
  size?: 'sm' | 'md' | 'lg';
}

const SharedDropdowns: FunctionComponent<SharedDropdownsProps> = ({
  tab,
  visualIds,
  globalSelections,
  hideLabels,
  optionalDropdowns,
  size = 'md',
}) => {
  const {
    visualRefs,
    sharedDropdowns,
    visualsData,
    dropdownSelections,
    reportConfig,
    updateDropdownSelections,
    updateFilesDropdownSelections,
  } = useContext(ReportContext);
  const posthogEvent = usePosthog();
  const { pathname } = useLocation();
  const origin = pathname.split('/')[1];
  const isReportSplit =
    reportConfig.report_version === 'v2' &&
    reportConfig.report_type !== 'Customer Decision Tree';
  const currentDropdowns = optionalDropdowns ?? sharedDropdowns?.[tab];

  useEffect(() => {
    if (
      globalSelections &&
      dropdownSelections[tab]?.[visualIds[0]] &&
      currentDropdowns?.length
    ) {
      globalSelections.forEach(({ label, selectedKey }) => {
        const dropdownId = currentDropdowns.find(
          (dropdown) => label === dropdown.label
        )?.id;
        if (dropdownId) {
          updateDropdownSelections(tab, dropdownId, selectedKey);
        }
      });
    }
  }, [globalSelections]);

  if (
    !currentDropdowns?.length ||
    currentDropdowns.every(({ options }) => !options.length) ||
    !visualsData[visualIds[0]]
  ) {
    return null;
  }

  const updateDropdownSelection = ({
    selectedItem,
    dropdownId,
    fetch,
  }: UpdateDropdownSelectionParameters) => {
    posthogEvent(homepageSharedDropdownClick, {
      selectedItem,
      origin,
    });
    if (isReportSplit && !fetch) {
      const { visualDropdownConfig, sharedDropdown } =
        visualsData[visualIds[0]]?.files[
          dropdownSelections[tab]?.[visualIds[0]]
        ] ?? {};
      const dropdownIndexInConfig = visualDropdownConfig.findIndex(
        (dd) => dd.id === dropdownId
      );
      const dropdownIndexInShared = sharedDropdown.findIndex(
        (dd) => dd.id === dropdownId
      );
      const selectedKey = sharedDropdown[dropdownIndexInShared].options.find(
        (opt) => opt.label === selectedItem
      ).key;
      updateFilesDropdownSelections(
        dropdownIndexInConfig,
        selectedKey as number,
        visualIds
      );
    } else {
      const dropdownIndexInShared = currentDropdowns.findIndex(
        (dd) => dd.id === dropdownId
      );
      const selectedKey = currentDropdowns[dropdownIndexInShared].options.find(
        (opt) => opt.label === selectedItem
      ).key;
      updateDropdownSelections(tab, dropdownId, selectedKey);
    }
  };

  const dropdowns = currentDropdowns
    .map((dropdown) => {
      const { fetch, label, options } = dropdown;
      const isDropdownDisabled = globalSelections?.some(
        ({ label: globalDropdownLabel }) => globalDropdownLabel === label
      );
      if (isDropdownDisabled && options.length === 0) {
        return null;
      }
      if (typeof fetch !== 'boolean' || fetch) {
        return {
          dropdown,
          dropdownConfig: visualsData[visualIds[0]]?.dropdownConfig,
          dropdownSelection: dropdownSelections[tab]?.[visualIds[0]],
          isDropdownDisabled,
        };
      } else if (
        visualsData[visualIds[0]]?.files?.[
          dropdownSelections[tab]?.[visualIds[0]]
        ]?.sharedDropdown
      ) {
        return {
          dropdown,
          dropdownConfig:
            visualsData[visualIds[0]]?.files[
              dropdownSelections[tab]?.[visualIds[0]]
            ]?.sharedDropdown,
          dropdownSelection: visualsData[visualIds[0]]?.fileDropdownSelections,
          isDropdownDisabled,
        };
      }
    })
    .filter(Boolean);

  const renderDropdowns = (
    conditionCallback: (dropdownData: { dropdown: DropdownConfig }) => boolean
  ) =>
    dropdowns
      .filter(conditionCallback)
      .map(
        ({
          dropdown,
          dropdownConfig,
          dropdownSelection,
          isDropdownDisabled,
        }) => (
          <VisualDropdown
            dropdownElementId={`shared-dropdown-${dropdown.id}`}
            dropdown={dropdown}
            key={dropdown.id}
            dropdownConfig={dropdownConfig}
            dropdownSelection={dropdownSelection}
            hideLabel={hideLabels}
            size={size}
            readOnly={isDropdownDisabled}
            updateDropdownSelection={updateDropdownSelection}
          />
        )
      );

  return (
    <div
      className="flex justify-space-between"
      ref={(el) => (visualRefs.current[`${SHARED_DROPDOWN_KEY}-${tab}`] = el)}
    >
      <div className="visual-dropdowns shared">
        {renderDropdowns(({ dropdown: { switcher } }) => !switcher)}
      </div>
      <div className="visual-dropdowns shared visual-dropdowns--switchers">
        {renderDropdowns(({ dropdown: { switcher } }) => switcher)}
      </div>
    </div>
  );
};

export default SharedDropdowns;
