import { Warning } from '@carbon/icons-react';
import { hasInvalidItems } from '../../../utils/attributeUtils';
import type { UploadData } from '../../../reducers/AttributesReducer';
import type { FunctionComponent } from 'react';

interface AttributeErrorNotificationsProps {
  uploadData: UploadData;
}

const AttributeErrorNotifications: FunctionComponent<
  AttributeErrorNotificationsProps
> = ({ uploadData }) => {
  return (
    <div className="AttributeValidationGrid__notification-wrapper">
      {hasInvalidItems(uploadData?.products) && (
        <div className="AttributeValidationGrid__notification">
          <Warning
            size={18}
            className="AttributeValidationGrid__warning-icon"
          />
          Product not found in your database. Please re-upload the file with
          SKUs so they match existing product ID’s.
        </div>
      )}
      {hasInvalidItems(uploadData?.attributes) && (
        <div className="AttributeValidationGrid__notification">
          <Warning
            size={18}
            className="AttributeValidationGrid__warning-icon"
          />
          Attribute name doesn’t match any existing attribute group name. Please
          re-upload the file with the correct attribute name as the column
          header.
        </div>
      )}
    </div>
  );
};

export default AttributeErrorNotifications;
