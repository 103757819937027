import type { HierarchyNode } from 'd3';
import {
  RESET_SELECTION,
  TOGGLE_SAVE_STATE,
  UPDATE_ASSORTMENT_DATA,
  UPDATE_LAST_SAVED_DATA,
  UPDATE_CDT_NODES,
  UPDATE_CDT_ROOT_NAME,
  UPDATE_SAVING_MESSAGE,
  UPDATE_SELECTED_NODE,
  UPDATE_SELECTED_PRODUCTS,
  INITIAL_LOAD,
  UPDATE_ALL_OPTIMISER_DATA,
  UPDATE_OPTIMISER_ROWS,
  UPDATE_OPTIMISER_STRATEGIES,
  UPDATING_OPTIMISATION,
  UPDATING_OPTIMISATION_STATUS,
  VALIDATE_OPTIMISER,
  UPDATE_HIGHLIGHT,
  UPDATE_HIGHLIGHT_LEGEND,
} from '../constants/reducers';
import type { NodeMetrics } from '../utils/CDTUtils';
import {
  deriveCDTData,
  deriveUnsavedChanges,
  getHighlightItems,
  getInitialAssortmentData,
} from '../utils/CDTUtils';
import type { OptimiserStrategy } from './OptimiserFormReducer';

export interface CDTNodeData extends NodeMetrics {
  _directSubordinates?: number;
  _expanded?: boolean;
  _totalSubordinates?: number;
  id: number | string;
  name: string;
  parentNodeId: string | number;
  level: number;
}

export interface CDTNode {
  data: CDTNodeData;
  depth: number;
  id: number | string;
  compactEven: boolean;
  firstCompact: boolean;
  firstCompactNode: CDTNode;
  flexCompactDim: number[];
  parent: CDTNode;
  height: number;
  width: number;
  x: number;
  x0: number;
  y: number;
  y0: number;
  children?: CDTNode[];
}

export interface Decision {
  id: string;
  value: string;
  order?: number;
}

export interface NonPlanAssortmentData {
  readonly id: string;
  readonly PRODUCT: string | Point<string>;
  readonly PRODUCT_ID: string;
  readonly PRODUCT_SKU: string;
  readonly adj_memb_pen: NumberPoint;
  readonly avg_basket_spend: NumberPoint;
  readonly avg_wkly_sales: NumberPoint;
  readonly avg_wkly_units: NumberPoint;
  readonly brand_name: string;
  readonly comp_rank1: NumberPoint;
  readonly comp_rank2: NumberPoint;
  readonly comp_rank3: NumberPoint;
  readonly comp_score1: NumberPoint;
  readonly comp_score2: NumberPoint;
  readonly comp_score3: NumberPoint;
  readonly extension_4_name: string;
  readonly extension_meas_3: NumberPoint;
  readonly hierarchy_lvl3_name: string;
  readonly hierarchy_lvl4_name: string;
  readonly hierarchy_lvl5_name: string;
  readonly memb_3tile_pen: NumberPoint;
  readonly memb_measure_col1_spend: NumberPoint;
  readonly memb_measure_col1_spend_pct: NumberPoint;
  readonly memb_measure_col1_spend_index: NumberPoint;
  readonly memb_measure_col1_units: NumberPoint;
  readonly memb_measure_col1_units_pct: NumberPoint;
  readonly memb_measure_col1_units_index?: NumberPoint;
  readonly memb_measure_col2_spend: NumberPoint;
  readonly memb_measure_col2_spend_pct: NumberPoint;
  readonly memb_measure_col2_spend_index?: NumberPoint;
  readonly memb_measure_col2_units: NumberPoint;
  readonly memb_measure_col2_units_pct: NumberPoint;
  readonly memb_measure_col2_units_index?: NumberPoint;
  readonly memb_measure_col3_spend: NumberPoint;
  readonly memb_measure_col3_spend_pct: NumberPoint;
  readonly memb_measure_col3_spend_index?: NumberPoint;
  readonly memb_measure_col3_units: NumberPoint;
  readonly memb_measure_col3_units_pct: NumberPoint;
  readonly memb_measure_col3_units_index?: NumberPoint;
  readonly own_label_name: string;
  readonly pct_custs: NumberPoint;
  readonly pct_profit: NumberPoint;
  readonly pct_profit_share: NumberPoint;
  readonly pct_sku_spend: NumberPoint;
  readonly pct_sku_units: NumberPoint;
  readonly pct_spend: NumberPoint;
  readonly pct_units: NumberPoint;
  readonly picking_order: NumberPoint;
  readonly ppp: NumberPoint;
  readonly product_size_value_name: string;
  readonly rank_quad: NumberPoint;
  readonly risk_pct: NumberPoint;
  readonly risk_spend: NumberPoint;
  readonly spend: NumberPoint;
  readonly stores: NumberPoint;
  readonly cd1: Decision;
  readonly cd2: Decision;
  readonly cd3: Decision;
  readonly subs_1_name?: string;
  readonly subs_1_tfr?: NumberPoint;
  readonly subs_2_name?: string;
  readonly subs_2_tfr?: NumberPoint;
  readonly subs_3_name?: string;
  readonly subs_3_tfr?: NumberPoint;
  readonly subs_4_name?: string;
  readonly subs_4_tfr?: NumberPoint;
  readonly subs_5_name?: string;
  readonly subs_5_tfr?: NumberPoint;
  readonly tfr_pct: NumberPoint;
  readonly tfr_spend: NumberPoint;
  readonly units: NumberPoint;
  readonly wtd_sales: NumberPoint;
  readonly wtd_units: NumberPoint;
  readonly action?: Point<string>;
  readonly showSubstitutes?: boolean;
}

export interface PlanAssortmentTotalData {
  readonly id: string;
  readonly PRODUCT: string | Point<string>;
  readonly PRODUCT_ID: string;
  readonly PRODUCT_SKU: string;
  readonly LOCATION?: string;
  readonly proj_dist_pre: NumberPoint;
  readonly proj_dist_post: NumberPoint;
  readonly proj_sales_pre: NumberPoint;
  readonly proj_sales_post: NumberPoint;
  readonly proj_margin_pre: NumberPoint;
  readonly proj_margin_post: NumberPoint;
  readonly total: Point<string>;
  readonly adj_memb_pen: NumberPoint;
  readonly avg_basket_spend: NumberPoint;
  readonly brand_name: string;
  readonly cd1: Decision;
  readonly cd2: Decision;
  readonly cd3: Decision;
  readonly comp_rank1: NumberPoint;
  readonly comp_rank2: NumberPoint;
  readonly comp_rank3: NumberPoint;
  readonly comp_score1: NumberPoint;
  readonly comp_score2: NumberPoint;
  readonly comp_score3: NumberPoint;
  readonly extension_3_name: string;
  readonly extension_4_name: string;
  readonly extension_meas_3: NumberPoint;
  readonly hierarchy_lvl3_name: string;
  readonly hierarchy_lvl4_name: string;
  readonly hierarchy_lvl5_name: string;
  readonly memb_3tile_pen: NumberPoint;
  readonly notes: string;
  readonly own_label_name: string;
  readonly pct_acv: NumberPoint;
  readonly pct_ots_custs: NumberPoint;
  readonly pct_profit: NumberPoint;
  readonly pct_profit_share: NumberPoint;
  readonly pct_sku_spend: NumberPoint;
  readonly pct_sku_units: NumberPoint;
  readonly pct_solus_custs: NumberPoint;
  readonly pct_spend: NumberPoint;
  readonly pct_units: NumberPoint;
  readonly picking_order: NumberPoint;
  readonly picking_order_final: NumberPoint;
  readonly ppp: NumberPoint;
  readonly prod_status: string;
  readonly product_size_value_name: string;
  readonly proj_dist_chg: NumberPoint;
  readonly proj_dist_pchg: NumberPoint;
  readonly proj_margin_chg: NumberPoint;
  readonly proj_margin_pchg: NumberPoint;
  readonly proj_spend_chg: NumberPoint;
  readonly proj_spend_pchg: NumberPoint;
  readonly proj_spend_post: NumberPoint;
  readonly proj_spend_pre: NumberPoint;
  readonly rank_quad: NumberPoint;
  readonly risk_pct: NumberPoint;
  readonly risk_spend: NumberPoint;
  readonly rr: NumberPoint;
  readonly spend: NumberPoint;
  readonly spend_part: NumberPoint;
  readonly spend_pchg: NumberPoint;
  readonly subs_1_name: string;
  readonly subs_1_tfr: NumberPoint;
  readonly subs_2_name: string;
  readonly subs_2_tfr: NumberPoint;
  readonly subs_3_name: string;
  readonly subs_3_tfr: NumberPoint;
  readonly subs_4_name: string;
  readonly subs_4_tfr: NumberPoint;
  readonly subs_5_name: string;
  readonly subs_5_tfr: NumberPoint;
  readonly supplier_name: string;
  readonly tfr_pct: NumberPoint;
  readonly tfr_spend: NumberPoint;
  readonly units: NumberPoint;
  readonly units_part: NumberPoint;
  readonly weeks_on_sale: NumberPoint;
  readonly weeks_since_sale: NumberPoint;
  readonly wtd_sales: NumberPoint;
  readonly wtd_units: NumberPoint;
}

export type PlanAssortmentPlanData = PlanAssortmentTotalData & {
  readonly [key: `plan${string}`]: Point<string>;
};

export type AssortmentData =
  | NonPlanAssortmentData
  | PlanAssortmentTotalData
  | PlanAssortmentPlanData;

export interface ClusterOptimiserStrategy {
  strategy: OptimiserStrategy;
  targetSkuGoal?: number;
  totalSkuCount?: number;
}

export interface OptimisationStatus {
  progress: number;
  message: string;
}

type DendroSaving = {
  PRODUCT_ID: string;
  cd1: Decision;
  cd2: Decision;
  cd3: Decision;
};

export type AssortmentDendroPayload = {
  path: string;
  payload: {
    rows: Record<string, DendroSaving[]>;
    cdtRootName: string;
  };
};

type OptimisationSaving = {
  PRODUCT_ID: string;
  notes: string;
  action?: Point<string>;
  [key: `plan${number}`]: Point<string>;
};

export type AssortmentOptimisationPayload = {
  path: string;
  payload: {
    rows: Record<string, OptimisationSaving[]>;
  };
};

export type HighlightType = 'continuous' | 'discrete' | 'index';
export interface Highlight {
  display: boolean;
  title?: string;
  label: string | null;
  by: string | null;
  type: HighlightType;
  targetProductId?: string;
  cellId?: string;
}
export interface HighlightLegendItem {
  range?: { min: number; max: number };
  label: string;
  colour: string;
  info?: string;
  showLabel?: boolean;
}

export interface HighlightLegendEntry {
  type: HighlightType | null;
  label: string;
  key: string;
  items: HighlightLegendItem[];
  hidden?: boolean;
}

export interface AssortmentState {
  unsavedChanges: boolean;
  optimiserValid: boolean;
  assortmentData: AssortmentData[];
  lastSavedData: AssortmentData[];
  optimiserData: { [key: string]: AssortmentData[] };
  optimiserStrategies: { [key: string]: ClusterOptimiserStrategy };
  cdtData: CDTNodeData[];
  cdtNodes: CDTNode[];
  cdtRootName: string;
  viewCDT: boolean;
  saving: boolean;
  savingMessage: string;
  selectedProducts: string[];
  selectedNode: HierarchyNode<DendrogramNode>;
  updatingOptimisation: boolean;
  optimisationStatus: OptimisationStatus;
  highlight: Highlight;
  highlightLegend: HighlightLegendEntry[];
}

export const initialAssortmentState: AssortmentState = {
  unsavedChanges: false,
  optimiserValid: true,
  assortmentData: [],
  lastSavedData: [],
  optimiserData: {},
  optimiserStrategies: {},
  cdtData: [],
  cdtNodes: [],
  cdtRootName: 'ROOT',
  viewCDT: false,
  saving: false,
  savingMessage: '',
  selectedProducts: [],
  selectedNode: null,
  updatingOptimisation: false,
  optimisationStatus: {
    progress: 0,
    message: '',
  },
  highlight: {
    display: false,
    by: null,
    label: null,
    type: null,
  },
  highlightLegend: null,
};

interface InitialLoad {
  type: typeof INITIAL_LOAD;
  assortmentData: AssortmentData[];
  cdtRootName: string;
  headers: Header[];
}
interface UpdateLastSavedData {
  type: typeof UPDATE_LAST_SAVED_DATA;
  lastSavedData: AssortmentData[];
}
interface UpdateAssortmentData {
  type: typeof UPDATE_ASSORTMENT_DATA;
  assortmentData: AssortmentData[];
}

interface UpdateAllOptimiserData {
  type: typeof UPDATE_ALL_OPTIMISER_DATA;
  optimiserData: { [key: string]: AssortmentData[] };
}
interface UpdateOptimiserRows {
  type: typeof UPDATE_OPTIMISER_ROWS;
  optimiserData: AssortmentData[];
  dropdownKey: string;
}

interface UpdateOptimiserStrategies {
  type: typeof UPDATE_OPTIMISER_STRATEGIES;
  optimiserStrategies: { [key: string]: ClusterOptimiserStrategy };
}

interface UpdateCDTNodes {
  type: typeof UPDATE_CDT_NODES;
  cdtNodes: CDTNode[];
}

interface UpdateCDTRootName {
  type: typeof UPDATE_CDT_ROOT_NAME;
  cdtRootName: string;
}

interface ToggleSaveState {
  type: typeof TOGGLE_SAVE_STATE;
  saving: boolean;
}

interface UpdateSavingMessage {
  type: typeof UPDATE_SAVING_MESSAGE;
  savingMessage: string;
}

interface UpdateProductSelection {
  type: typeof UPDATE_SELECTED_PRODUCTS;
  productIds: string[];
}

interface UpdateSelectedNode {
  type: typeof UPDATE_SELECTED_NODE;
  node: HierarchyNode<DendrogramNode>;
}

interface ResetSelection {
  type: typeof RESET_SELECTION;
}

interface UpdatingOptimisation {
  type: typeof UPDATING_OPTIMISATION;
  updating: boolean;
}
interface UpdatingOptimisationStatus {
  type: typeof UPDATING_OPTIMISATION_STATUS;
  status: OptimisationStatus;
}

interface ValidateOptimiser {
  type: typeof VALIDATE_OPTIMISER;
  valid: boolean;
}

interface UpdateHighlightLegend {
  type: typeof UPDATE_HIGHLIGHT_LEGEND;
  highlightLegend: HighlightLegendEntry[];
}
interface UpdateHighlight {
  type: typeof UPDATE_HIGHLIGHT;
  highlight: Highlight;
}

export type AssortmentAction =
  | InitialLoad
  | UpdateAssortmentData
  | UpdateLastSavedData
  | UpdateAllOptimiserData
  | UpdateOptimiserRows
  | UpdateOptimiserStrategies
  | UpdateCDTNodes
  | UpdateCDTRootName
  | ToggleSaveState
  | UpdateSavingMessage
  | UpdateProductSelection
  | UpdateSelectedNode
  | ResetSelection
  | UpdatingOptimisation
  | UpdatingOptimisationStatus
  | ValidateOptimiser
  | UpdateHighlightLegend
  | UpdateHighlight;

const assortmentReducer = (
  state: AssortmentState,
  action: AssortmentAction
): AssortmentState => {
  switch (action.type) {
    case INITIAL_LOAD: {
      const rootName = action.cdtRootName ? action.cdtRootName : 'ROOT';
      const initialData = getInitialAssortmentData(action.assortmentData);
      const highlightItems = getHighlightItems(
        action.assortmentData,
        action.headers
      );

      return {
        ...state,
        assortmentData: initialData,
        lastSavedData: action.assortmentData,
        cdtRootName: rootName,
        highlightLegend: highlightItems,
        cdtData: deriveCDTData(initialData, rootName),
      };
    }

    case UPDATE_ASSORTMENT_DATA:
      return {
        ...state,
        assortmentData: action.assortmentData,
        cdtData: deriveCDTData(action.assortmentData, state.cdtRootName),
        unsavedChanges: deriveUnsavedChanges(
          state,
          initialAssortmentState,
          action.assortmentData,
          state.lastSavedData
        ),
      };

    case UPDATE_LAST_SAVED_DATA:
      return {
        ...state,
        lastSavedData: action.lastSavedData,
        unsavedChanges: deriveUnsavedChanges(
          state,
          initialAssortmentState,
          state.assortmentData,
          action.lastSavedData
        ),
      };

    case UPDATE_ALL_OPTIMISER_DATA:
      return {
        ...state,
        optimiserData: action.optimiserData,
      };

    case UPDATE_OPTIMISER_STRATEGIES:
      return {
        ...state,
        optimiserStrategies: action.optimiserStrategies,
      };

    case UPDATE_OPTIMISER_ROWS:
      return {
        ...state,
        optimiserData: {
          ...state.optimiserData,
          [action.dropdownKey]: action.optimiserData,
        },
      };

    case UPDATE_CDT_NODES:
      return {
        ...state,
        cdtNodes: action.cdtNodes,
      };

    case UPDATE_CDT_ROOT_NAME:
      return {
        ...state,
        cdtRootName: action.cdtRootName,
        cdtData: deriveCDTData(state.assortmentData, action.cdtRootName),
        unsavedChanges: true,
      };

    case TOGGLE_SAVE_STATE:
      return {
        ...state,
        saving: action.saving,
        savingMessage: action.saving ? state.savingMessage : '',
      };

    case UPDATE_SAVING_MESSAGE:
      return {
        ...state,
        savingMessage: action.savingMessage,
      };

    case UPDATE_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.productIds,
      };

    case UPDATE_SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.node,
      };

    case RESET_SELECTION:
      return {
        ...state,
        selectedNode: null,
        selectedProducts: [],
      };

    case UPDATING_OPTIMISATION:
      return {
        ...state,
        updatingOptimisation: action.updating,
      };

    case UPDATING_OPTIMISATION_STATUS:
      return {
        ...state,
        optimisationStatus: action.status,
      };

    case VALIDATE_OPTIMISER:
      return {
        ...state,
        optimiserValid: action.valid,
      };

    case UPDATE_HIGHLIGHT_LEGEND:
      return {
        ...state,
        highlightLegend: action.highlightLegend,
      };

    case UPDATE_HIGHLIGHT:
      return {
        ...state,
        highlight: action.highlight,
      };
  }
};

export default assortmentReducer;
