import { Outlet } from 'react-router-dom';
import AttributesProvider from '../../providers/AttributesProvider';

const AttributeLayout = () => {
  return (
    <AttributesProvider>
      <Outlet />
    </AttributesProvider>
  );
};

export default AttributeLayout;
